import React, { useEffect, useRef, useState } from "react";
import { NumericFormat } from "react-number-format";
import "./css/Ewacalculator.css";
const Ewacalculator = () => {
  const messagesEndRef = useRef(null);
  const [monthlySallary, setMonthlySallary] = useState(15000);
  const [sallaryDate, setSallaryDate] = useState(7);
  const [advanceWithdrawlDate, setAdvanceWithdrawlDate] = useState(27);
  const [noOfDaysTillSallary, setNoOfDaysTillSallary] = useState();
  const [noOfDaysTillSallaryError, setNoOfDaysTillSallaryError] = useState("");
  const [chargesPerDay, setChargesPerDay] = useState(0.9);
  const [interestPerc, setInterestPer] = useState(18);
  const [apr, setApr] = useState(0);
  const [accuredSallary, setAccuredSallary] = useState();
  const [accuredSallaryPer, setAccuredSallaryPer] = useState(80);
  const [advanceAmountEligible, setAdvanceAmountEligible] = useState();
  const [amountToWithdraw, setAmountToWithdraw] = useState(1000);
  const [transactionCharges, setTransactionCharges] = useState("");
  const [finalAmount, setFinalAmount] = useState("");
  const [amountError, setAmountError] = useState("");
  const [firstTime, setFirstTime] = useState(true);
  const [colour, setColour] = useState({
    monthlySallary: true,
    sallaryDate: true,
    advanceWithdrawlDate: true,
    accuredSallaryPer: true,
    chargesPerDay: true,
    amountToWithdraw: true,
  });

  useEffect(() => {
    if (chargesPerDay) {
      let interestTemp = (chargesPerDay / 1000) * 365 * 100;
      console.log("interestTemp", interestTemp);
      setInterestPer(interestTemp);
    }
  }, [chargesPerDay]);

  useEffect(() => {
    if (monthlySallary) {
      setTransactionCharges("");
      setFinalAmount("");
    }
  }, [monthlySallary]);

  useEffect(() => {
    if (advanceWithdrawlDate && sallaryDate) {
      let noOfDaysTillSallaryTemp =
        30 - parseInt(advanceWithdrawlDate) + parseInt(sallaryDate);
      if (noOfDaysTillSallaryTemp < 7) {
        setNoOfDaysTillSallaryError("This field can not be less than 7");
      } else {
        setNoOfDaysTillSallaryError("");
      }
      setNoOfDaysTillSallary(
        30 - parseInt(advanceWithdrawlDate) + parseInt(sallaryDate)
      );
    }
  }, [advanceWithdrawlDate, sallaryDate]);

  useEffect(() => {
    if (monthlySallary && advanceWithdrawlDate) {
      setAccuredSallary(
        (parseInt(monthlySallary) / 30) * parseInt(advanceWithdrawlDate)
      );
    }
  }, [monthlySallary, advanceWithdrawlDate, sallaryDate]);

  useEffect(() => {
    if (accuredSallary && accuredSallaryPer) {
      setAdvanceAmountEligible(
        (parseInt(accuredSallary) * accuredSallaryPer) / 100
      );
    }
  }, [accuredSallary, accuredSallaryPer]);

  useEffect(() => {
    if (amountToWithdraw && interestPerc && noOfDaysTillSallary && firstTime) {
      let charge = (
        (parseInt(amountToWithdraw) *
          chargesPerDay *
          parseInt(noOfDaysTillSallary)) /
        1000
      ).toFixed(2);
      // let charge = (
      //   ((parseInt(amountToWithdraw) * parseInt(interestPerc)) / 100 / 365) *
      //   parseInt(noOfDaysTillSallary)
      // ).toFixed(2);

      let final = (parseInt(amountToWithdraw) - charge).toFixed(2);
      // let apr = (parseInt(amountToWithdraw) * parseInt(interestPerc)) / final;
      let apr = (chargesPerDay * 365 * 100) / 1000;
      setApr(apr.toFixed(2));
      setTransactionCharges(charge);
      setFinalAmount(final);
      setFirstTime(false);
      setColour((prev) => ({
        ...prev,
        transactionCharges: true,
        finalAmount: true,
      }));
    }
  }, [amountToWithdraw, interestPerc, noOfDaysTillSallary, firstTime]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // let charge = (
    //   ((parseInt(amountToWithdraw) * parseInt(interestPerc)) / 100 / 365) *
    //   parseInt(noOfDaysTillSallary)
    // ).toFixed(2);
    let charge = (
      (parseInt(amountToWithdraw) *
        chargesPerDay *
        parseInt(noOfDaysTillSallary)) /
      1000
    ).toFixed(2);
    let final = (parseInt(amountToWithdraw) - charge).toFixed(2);
    // let apr = (parseInt(amountToWithdraw) * parseInt(interestPerc)) / final;
    let apr = (chargesPerDay * 365 * 100) / 1000;
    setApr(apr.toFixed(2));
    setTransactionCharges(charge);
    setFinalAmount(final);
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const clearForm = () => {
    setMonthlySallary(0);
    setAccuredSallary(0);
    setAmountToWithdraw(0);
    setAccuredSallaryPer(0);
    setAdvanceWithdrawlDate(0);
    setAdvanceAmountEligible(0);
    setInterestPer(0);
    setNoOfDaysTillSallary(0);
    setTransactionCharges();
    setFinalAmount();
    setSallaryDate(0);
    setTimeout(() => {
      setColour({});
    }, 50);
  };

  return (
    <div className="Ewacalculator-section blueBgSec">
      <div className="container">
        <div className="row">
          <div className="col-xl-2"></div>
          <div className="col-xl-8 col-md-12">
            <div className="table-inner mt-3">
              <div className="table-header text-uppercase">
                SALARY ADVANCE & INTEREST CALCULATOR
              </div>

              <form action="" method="post" onSubmit={handleSubmit}>
                <div className="table">
                  <div className="caclRowGrid bg-blue">
                    <span>a. Monthly salary </span>
                    <span className="inputField text-end">
                      <NumericFormat
                        className="form-control"
                        value={monthlySallary}
                        style={{
                          backgroundColor: colour?.monthlySallary
                            ? "#e3ffe3"
                            : "",
                        }}
                        onValueChange={(values) => {
                          setMonthlySallary(values.value);
                          setColour((prev) => ({
                            ...prev,
                            monthlySallary: true,
                          }));
                        }}
                        prefix={"₹ "}
                        suffix=".00"
                      />
                    </span>
                  </div>
                  <div className="caclRowGrid">
                    <span>b. Salary payment date ( day of next month )</span>
                    <span className="inputField text-end">
                      <input
                        type="number"
                        min={0}
                        max={30}
                        className="form-control"
                        value={sallaryDate}
                        onChange={(e) => {
                          setSallaryDate(e.target.value);
                          setColour((prev) => ({ ...prev, sallaryDate: true }));
                        }}
                        disabled={monthlySallary ? false : true}
                        style={{
                          backgroundColor: colour?.sallaryDate ? "#e3ffe3" : "",
                        }}
                        required
                      />
                    </span>
                  </div>
                  <div className="caclRowGrid">
                    <span>c. Advance request date ( day of this month )</span>
                    <span className="inputField text-end">
                      <input
                        type="number"
                        min={0}
                        max={30}
                        className="form-control "
                        value={advanceWithdrawlDate}
                        onChange={(e) => {
                          setAdvanceWithdrawlDate(e.target.value);
                          setColour((prev) => ({
                            ...prev,
                            advanceWithdrawlDate: true,
                          }));
                        }}
                        style={{
                          backgroundColor: colour?.advanceWithdrawlDate
                            ? "#e3ffe3"
                            : "",
                        }}
                        required
                      />
                    </span>
                  </div>
                  <div className="d-flex justify-content-end">
                    <small className="text-danger">
                      {noOfDaysTillSallaryError}
                    </small>
                  </div>
                  <div className="caclRowGrid">
                    <span>
                      d. Number of days till salary payment date (30 - c + b)
                    </span>
                    <span className=" text-end">
                      <input
                        type="number"
                        min={7}
                        className="form-control"
                        value={noOfDaysTillSallary}
                        disabled={true}
                        required
                        style={{
                          backgroundColor:
                            noOfDaysTillSallaryError?.length > 0
                              ? "#f8d7da"
                              : "",
                        }}
                      />
                    </span>
                  </div>
                  <div className="caclRowGrid">
                    <span>e. Charges per day per 1000</span>
                    <span className="text-end">
                      {/* <div className="per">%</div> */}
                      <NumericFormat
                        className="form-control"
                        value={chargesPerDay}
                        style={{
                          backgroundColor: colour?.chargesPerDay
                            ? "#e3ffe3"
                            : "",
                        }}
                        onValueChange={(values) => {
                          console.log("value he", values);
                          setChargesPerDay(values.value);
                          setColour((prev) => ({
                            ...prev,
                            chargesPerDay: true,
                          }));
                        }}
                        prefix={"₹ "}
                        suffix=""
                      />
                      {/* <input
                        type="number"
                        // min={0}
                        max={100}
                        className="form-control"
                        value={chargesPerDay}
                        onChange={(e) => {
                          setChargesPerDay(e.target.value);
                          setColour((prev) => ({
                            ...prev,
                            chargesPerDay: true,
                          }));
                        }}
                        style={{
                          backgroundColor: colour?.chargesPerDay
                            ? "#e3ffe3"
                            : "",
                        }}
                        required
                      /> */}
                    </span>
                  </div>
                  <div className="caclRowGrid">
                    <span>
                      f. Accrued salary as on request date [ a/30 x c ]
                    </span>
                    <span className=" inputField text-end">
                      <NumericFormat
                        className="form-control"
                        value={accuredSallary?.toFixed(2)}
                        disabled={true}
                        prefix={"₹ "}
                      />
                    </span>
                  </div>
                  <div className="caclRowGrid">
                    <span>
                      g. Employee eligibility as percentage against accrued
                      salary{" "}
                    </span>
                    <span className="inputField text-end">
                      <div className="per">%</div>
                      <input
                        type="number"
                        min={1}
                        max={100}
                        className="form-control new"
                        value={accuredSallaryPer}
                        onChange={(e) => {
                          setAccuredSallaryPer(e.target.value);
                          setColour((prev) => ({
                            ...prev,
                            accuredSallaryPer: true,
                          }));
                        }}
                        style={{
                          backgroundColor: colour?.accuredSallaryPer
                            ? "#e3ffe3"
                            : "",
                        }}
                        required
                      />
                    </span>
                  </div>
                  <div className="caclRowGrid">
                    <span>
                      h. Amount available for employee to withdraw [ f x g ]
                    </span>
                    <span className=" inputField text-end">
                      <NumericFormat
                        className="form-control"
                        value={advanceAmountEligible}
                        disabled={true}
                        onValueChange={(values) => {
                          // if (!firstTime) {
                          setAdvanceAmountEligible(values.value);
                          // }
                        }}
                        prefix={"₹ "}
                        suffix=".00"
                      />
                    </span>
                  </div>
                  <div className="d-flex justify-content-end">
                    <small className="text-danger">{amountError}</small>
                  </div>
                  <div className="caclRowGrid">
                    <span>i. Requested amount by the employee</span>
                    <span className="inputField text-end">
                      <NumericFormat
                        className="form-control"
                        value={amountToWithdraw}
                        min={1000}
                        style={{
                          backgroundColor:
                            amountError?.length > 0
                              ? "#f8d7da"
                              : colour?.amountToWithdraw
                              ? "#e3ffe3"
                              : "",
                        }}
                        onValueChange={(values) => {
                          if (
                            parseInt(values.value) >
                            parseInt(advanceAmountEligible)
                          ) {
                            setAmountToWithdraw("");
                            setAmountError(
                              "Can not enter more than eligible amount."
                            );
                            // setTransactionCharges("");
                            // setFinalAmount("");
                            setAmountToWithdraw(parseInt(values.value));
                            setColour((prev) => ({
                              ...prev,
                              amountToWithdraw: true,
                            }));
                          } else {
                            setAmountError(
                              "Amount should be greater than 1000."
                            );
                            // setTransactionCharges("");
                            // setFinalAmount("");
                            setAmountToWithdraw(parseInt(values.value));
                            setColour((prev) => ({
                              ...prev,
                              amountToWithdraw: true,
                            }));
                            if (parseInt(values.value) % 1000 === 0) {
                              setAmountToWithdraw(parseInt(values.value));
                              setAmountError("");
                              setColour((prev) => ({
                                ...prev,
                                amountToWithdraw: true,
                              }));
                            } else {
                              setAmountError(
                                "Amount should be multiple of 1000."
                              );
                              // setTransactionCharges("");
                              // setFinalAmount("");
                              setAmountToWithdraw(parseInt(values.value));
                              setColour((prev) => ({
                                ...prev,
                                amountToWithdraw: true,
                              }));
                            }
                          }
                        }}
                        prefix={"₹ "}
                        suffix=".00"
                      />
                      {/* <div className="rs">₹</div>
                      <input
                        type="number"
                        min={0}
                        max={parseInt(advanceAmountEligible)}
                        className="form-control"
                        value={amountToWithdraw.toFixed(2)}
                        required
                        style={{
                          backgroundColor: colour?.amountToWithdraw
                            ? "#e3ffe3"
                            : "",
                        }}
                        onChange={(e) => {
                          if (
                            parseInt(e.target.value) >
                            parseInt(advanceAmountEligible)
                          ) {
                            setAmountToWithdraw("");
                            setAmountError(
                              "Can not enter more than eligible amount."
                            );
                            setTransactionCharges("");
                            setFinalAmount("");
                            setAmountToWithdraw(parseInt(e.target.value));
                            setColour((prev) => ({
                              ...prev,
                              amountToWithdraw: true,
                            }));
                          } else {
                            setAmountError(
                              "Amount should be greater than 1000."
                            );
                            setTransactionCharges("");
                            setFinalAmount("");
                            setAmountToWithdraw(parseInt(e.target.value));
                            setColour((prev) => ({
                              ...prev,
                              amountToWithdraw: true,
                            }));
                            if (parseInt(e.target.value) % 1000 == 0) {
                              setAmountToWithdraw(parseInt(e.target.value));
                              setAmountError("");
                              setColour((prev) => ({
                                ...prev,
                                amountToWithdraw: true,
                              }));
                            } else {
                              setAmountError(
                                "Amount should be multiple of 1000."
                              );
                              setTransactionCharges("");
                              setFinalAmount("");
                              setAmountToWithdraw(parseInt(e.target.value));
                              setColour((prev) => ({
                                ...prev,
                                amountToWithdraw: true,
                              }));
                            }
                          }
                        }}
                      /> */}
                    </span>
                  </div>
                  <div className="caclRowGrid my-3">
                    <div className="caclRowGridBtns">
                      <button
                        className={
                          amountError?.length > 0 ||
                          noOfDaysTillSallaryError?.length > 0
                            ? "btn-disabled"
                            : "btn-new"
                        }
                        type="submit"
                        disabled={
                          amountError?.length > 0 ||
                          noOfDaysTillSallaryError?.length > 0
                        }
                      >
                        CALCULATE
                      </button>
                      {/* <Button style={{ backgroundColor: "#216FB8",color:'white' }} className="btn-new" type="submit" disabled={amountError?.length > 0}>
                        CALCULATE
                      </Button> */}
                      <button
                        className="btn-new pink ms-5"
                        style={{ borderRadius: 10 }}
                        type="reset"
                        onClick={clearForm}
                      >
                        RESET
                      </button>
                    </div>
                  </div>
                  <div className="caclRowGrid" ref={messagesEndRef}>
                    <span style={{ fontWeight: "600" }}>
                      Interest charges for ({noOfDaysTillSallary}) number of
                      days
                    </span>
                    <span className="bg-blue inputField text-end">
                      <NumericFormat
                        className="form-control"
                        value={transactionCharges}
                        disabled={true}
                        style={{ fontWeight: "600" }}
                        prefix={"₹ "}
                      />
                    </span>
                  </div>
                  <div
                    className="caclRowGrid"
                    style={{
                      border: "2px solid #fff !Important",
                    }}
                  >
                    <span className="boldText">Annual Percentage Rate</span>
                    <span className="text-end inputField text-end boldText">
                      <NumericFormat
                        className="form-control"
                        value={apr}
                        disabled={true}
                        style={{ fontWeight: "600" }}
                        prefix={""}
                        suffix=" %"
                      />
                    </span>
                  </div>
                  <div
                    className="caclRowGrid"
                    style={{
                      border: "2px solid #fff !Important",
                    }}
                  >
                    <span className="boldText">Amount disbursed</span>
                    <span className="text-end inputField text-end boldText">
                      <NumericFormat
                        className="form-control"
                        value={finalAmount}
                        disabled={true}
                        style={{ fontWeight: "600" }}
                        prefix={"₹ "}
                      />
                    </span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ewacalculator;
