import React from "react";
import "./css/BankPartner.css";
import logo from "../components/assets/ssBank.png";
import hindon from "../components/assets/hindon.png";
const LendingPartners = () => {
  const handleContact = () => {
    window.open(
      "https://www.suryodaybank.com/loans/EWA-Personal-Loans-instape",
      "_blank"
    );
  };
  const handleContact2 = () => {
    window.open("https://hindon.co/lending-service-providers/", "_blank");
  };
  const handleTermsConditionsClick = () => {
    window.open("/terms_of_use", "_blank");
  };
  return (
    <>
      <div
        className="container mb-5"
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div className="bankText">
          <span>Our Lending Partners</span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              marginTop: 80,
              flexDirection: "column",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "300px",
              textAlign: "center",
            }}
          >
            <img src={logo} className="bankLogo" alt="Cinque Terre" />
            <span style={{ marginTop: 10, fontWeight: 600, color: "#2d348e" }}>
              Suryoday Small Finance Bank Limited
            </span>
            <div
              role="button"
              style={{
                marginTop: 19,
                width: 250,
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={handleContact}
              className="cta-btn-outline"
            >
              <span className="contact">Visit Website</span>
            </div>
          </div>
          <div
            style={{
              marginTop: 80,
              flexDirection: "column",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "300px",
              textAlign: "center",
            }}
          >
            <img src={hindon} className="bankLogo2" alt="Cinque Terre" />
            <span style={{ marginTop: 10, fontWeight: 600, color: "#2d348e" }}>
              Hindon Mercantile Limited
            </span>
            <div
              role="button"
              style={{
                marginTop: 19,
                width: 250,
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={handleContact2}
              className="cta-btn-outline"
            >
              <span className="contact">Visit Website</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LendingPartners;
