import React from "react";
import "./css/grivance.css";
const GrievanceRedressal = () => {
  return (
    <>
      <div
        className="container mb-5"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1 style={{ textAlign: "center" }}> Grievance</h1>
        <b style={{ textAlign: "center" }}>
          If you are not happy with the solution provided reach out to Grievance
          Officer.
        </b>
        <div className="write-to-us-section mt-3">
          <p>
            <b>Name</b>: Mr. Durairaj Ponnusamy
          </p>
          <p>
            <b>Address</b>: InstaPe Synergies Private Limited, Tainwala House,
            Road no.18, MIDC, Marol Andheri (East), MUMBAI 400093{" "}
          </p>

          <p>
            <b>Phone number</b>: +91 86554 08338
          </p>
          <p>
            <b>Timings</b>: From 9:30am to 6:00pm (2nd and 4th Saturday Holiday){" "}
          </p>
          <p>
            <b> Email ID</b>:
            <a
              href="mailto:nodalofficer@instape.com"
              style={{ color: "blue", marginLeft: 2 }}
            >
              nodalofficer@instape.com
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default GrievanceRedressal;
