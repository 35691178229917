import React, { useState, useEffect } from "react";
import "./css/Home.css";
// import homeimg from "../components/assets/mainFrame.png";
import mobileAtm from "../components/assets/1.gif";
import hrPartner from "../components/assets/2.gif";
import funds from "../components/assets/3.gif";
import payroll from "../components/assets/4.gif";
import pigi from "../components/assets/CTA.png";
import mobile from "../components/assets/instapay.gif";
// import mobileFrame from "../components/assets/mobileFrames.png";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Button } from "react-bootstrap";
import { Autoplay, Pagination } from "swiper/modules";
import desktopVideo from "../components/assets/horizontal.mp4";
import mobileVideo from "../components/assets/vertical.mp4";
import playstore from "../components/assets/google-play-badge.png";
// import googlePlay from "../components/assets/googlePlay.svg";
import pigim from "../components/assets/CTAMobile.png";
const Home = () => {
  const [count, setCount] = useState(1000);

  useEffect(() => {
    let interval;

    const startCounter = () => {
      interval = setInterval(() => {
        if (count > 9.0) {
          setCount((prevCount) => Math.max(prevCount - 8.3, 9.0));
        } else {
          clearInterval(interval);
          setTimeout(() => {
            setCount(1000);
            startCounter(); // Restart the counter after waiting for 5 seconds
          }, 5000); // Wait for 5 seconds before resetting to 1000
        }
      }, 30); // Adjust the interval as needed for the desired speed
    };

    startCounter();

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [count]); // Dependency on count to re-run the effect when count changes
  const handleContactUsClick = () => {
    window.location.href = "mailto:signup@instape.com";
  };
  const handleDownloadButton = () => {
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.instape";
  };
  return (
    <>
      <div>
        <div style={{ position: "relative" }}>
          <div className="desktopVideo">
            <video
              autoPlay
              loop
              muted
              style={{
                width: "100%",
                height: "650px",
                objectFit: "cover",
                background: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <source src={desktopVideo} type="video/mp4" height={80} />
            </video>
          </div>
          <div className="MobileVideo">
            <video
              autoPlay
              loop
              muted
              playsInline
              style={{
                width: "100%",
                height: "80%",
                objectFit: "cover",
                background: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <source src={mobileVideo} type="video/mp4" height={80} />
            </video>
          </div>
          <div
            style={{
              position: "absolute",
              top: -10,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="DesktopEmpower" style={{ textAlign: "center" }}>
              <span className="empower">
                Empower your team.
                <br />
                Foster unwavering loyalty.
              </span>
            </div>
            <div className="mobileEmpower" style={{ textAlign: "center" }}>
              <span className="empower">
                Empower your team.
                <br />
                Foster unwavering
                <br />
                loyalty.
              </span>
            </div>
            <div className="mt-4" style={{ textAlign: "center" }}>
              <span className="grantDesktop">
                Grant financial freedom to employees with secure early earnings
                access, preventing debt traps and unethical lenders.
              </span>
              <span className="grantMobile">
                Grant financial freedom to employees with<br></br>secure early
                earnings access, preventing debt<br></br>traps and unethical
                lenders.
              </span>
            </div>
          </div>
        </div>

        {/* <div className="mt-4 deskopImg">
          <img src={homeimg} className="img-fluid" alt="Cinque Terre" height="30" />
        </div>
        <div className="mt-4 mobileImg">
          <img src={mobileFrame} className="img-fluid" alt="Cinque Terre" height="30" />
        </div> */}
        <div className="container">
          <div
            className="mt-5 revolutiondesktop"
            style={{ textAlign: "center" }}
          >
            <span className="revolutionText ">
              Revolutionise Your Workforce’s<br></br> Financial Wellbeing
            </span>
          </div>
          <div
            className="mt-4 revolutionMobile"
            style={{ textAlign: "center" }}
          >
            <span className="revolutionText ">
              Revolutionise Your Workforce’s Financial <br></br> Wellbeing
            </span>
          </div>
          <div className="row mt-5 desktopSwiper">
            <div className="col-md-6 col-lg-3">
              <div
                style={{
                  background:
                    "linear-gradient(180deg, rgba(131, 164, 212, 0.12) 0%, rgba(182, 251, 255, 0.16) 100%), #FFF",
                  padding: 20,
                  borderRadius: "20.045px",
                  height: "350px",
                }}
              >
                <div className="row">
                  <span className="cardHeading">Mobile ATM</span>
                </div>
                <br></br>
                <div>
                  <span className="cardText">
                    Your employees can access their wages anytime, anywhere;
                    24X7!
                  </span>
                </div>
                <div className="d-flex justify-content-end mt-3">
                  <img
                    src={mobileAtm}
                    className="img-fluid"
                    alt="Cinque Terre"
                    style={{ width: "50%", height: "150px" }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div
                style={{
                  background:
                    "linear-gradient(180deg, rgba(97, 144, 232, 0.12) 0%, rgba(167, 191, 232, 0.16) 100%), #FFF",
                  padding: 20,
                  borderRadius: "20.045px",
                  height: "350px",
                }}
              >
                <div className="row">
                  <span className="cardHeading">Your HR Partner</span>
                </div>
                <br></br>
                <div>
                  <span className="cardText">
                    Customised product in line with your HR objectives
                  </span>
                </div>
                <div className="d-flex justify-content-end">
                  <img
                    src={hrPartner}
                    className="img-fluid"
                    alt="Cinque Terre"
                    style={{ width: "50%", height: "150px" }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 tablet ">
              <div
                style={{
                  background:
                    "linear-gradient(180deg, rgba(5, 117, 230, 0.12) 0%, rgba(2, 27, 121, 0.13) 100%), #FFF",
                  padding: 20,
                  borderRadius: "20.045px",
                  height: "350px",
                }}
              >
                <div className="row">
                  <span className="cardHeading">
                    30 seconds to <br></br>get your funds
                  </span>
                </div>
                <br></br>
                <div>
                  <span className="cardText">
                    100% digital journey with no manual intervention
                  </span>
                </div>
                <div className="d-flex justify-content-end align-items-end">
                  <img
                    src={funds}
                    className="img-fluid"
                    alt="Cinque Terre"
                    style={{ width: "50%", height: "150px" }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 tablet ">
              <div
                style={{
                  background:
                    "linear-gradient(180deg, rgba(31, 162, 255, 0.12) 0%, rgba(18, 216, 250, 0.14) 51.27%, rgba(166, 255, 203, 0.20) 100%), #FFF",
                  padding: 20,
                  borderRadius: "20.045px",
                  height: "350px",
                }}
              >
                <div className="row">
                  <span className="cardHeading">
                    Flexible Payroll<br></br>Solution
                  </span>
                </div>
                <br></br>
                <div>
                  <span className="cardText">
                    Employees pay just for the days you need, a smarter approach
                    to salary advances.
                  </span>
                </div>
                <div className="d-flex justify-content-end align-items-end">
                  <img
                    src={payroll}
                    className="img-fluid"
                    alt="Cinque Terre"
                    style={{ width: "50%", height: "150px" }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mobileSwiper" style={{ marginTop: "40px" }}>
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              modules={[Autoplay, Pagination]}
              className="mySwiper"
            >
              <SwiperSlide>
                <div
                  style={{
                    background:
                      "linear-gradient(180deg, rgba(131, 164, 212, 0.12) 0%, rgba(182, 251, 255, 0.16) 100%), #FFF",
                    padding: 20,
                    borderRadius: "20.045px",
                    height: "400px",
                  }}
                >
                  <div className="row">
                    <span className="cardHeading">Mobile ATM</span>
                  </div>
                  <br></br>
                  <div>
                    <span className="cardText">
                      Your employees can access their wages anytime, anywhere;
                      24X7!
                    </span>
                  </div>
                  <div className="d-flex justify-content-end mt-2">
                    <img
                      src={mobileAtm}
                      className="img-fluid"
                      alt="Cinque Terre"
                      style={{ width: "50%", height: "180px" }}
                    />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  style={{
                    background:
                      "linear-gradient(180deg, rgba(97, 144, 232, 0.12) 0%, rgba(167, 191, 232, 0.16) 100%), #FFF",
                    padding: 20,
                    borderRadius: "20.045px",
                    height: "400px",
                  }}
                >
                  <div className="row">
                    <span className="cardHeading">Your HR Partner</span>
                  </div>
                  <br></br>
                  <div>
                    <span className="cardText">
                      Customised product in line with your HR objectives
                    </span>
                  </div>
                  <div className="d-flex justify-content-end  mt-3">
                    <img
                      src={hrPartner}
                      className="img-fluid"
                      alt="Cinque Terre"
                      style={{ width: "50%", height: "180px" }}
                    />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  style={{
                    background:
                      "linear-gradient(180deg, rgba(5, 117, 230, 0.12) 0%, rgba(2, 27, 121, 0.13) 100%), #FFF",
                    padding: 20,
                    borderRadius: "20.045px",
                    height: "400px",
                  }}
                >
                  <div className="row">
                    <span className="cardHeading">
                      30 seconds to <br></br>get your funds
                    </span>
                  </div>
                  <br></br>
                  <div>
                    <span className="cardText">
                      100% digital journey with no manual intervention
                    </span>
                  </div>
                  <div className="d-flex justify-content-end align-items-end mt-3">
                    <img
                      src={funds}
                      className="img-fluid"
                      alt="Cinque Terre"
                      style={{ width: "50%", height: "180px" }}
                    />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  style={{
                    background:
                      "linear-gradient(180deg, rgba(31, 162, 255, 0.12) 0%, rgba(18, 216, 250, 0.14) 51.27%, rgba(166, 255, 203, 0.20) 100%), #FFF",
                    padding: 20,
                    borderRadius: "20.045px",
                    height: "400px",
                  }}
                >
                  <div className="row">
                    <span className="cardHeading">
                      Flexible Payroll<br></br>Solution
                    </span>
                  </div>
                  <br></br>
                  <div>
                    <span className="cardText">
                      Employees pay just for the days you need, a smarter
                      approach to salary advances.
                    </span>
                  </div>
                  <div className="d-flex justify-content-end align-items-end  mt-3">
                    <img
                      src={payroll}
                      className="img-fluid"
                      alt="Cinque Terre"
                      style={{ width: "50%", height: "180px" }}
                    />
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        <div
          className="bgImage container text-center"
          style={{ position: "relative" }}
        >
          <img src={pigi} className="img-fluid imgRupees" alt="Cinque Terre" />
          <div
            className="overlay-text"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="col-md-12 d-flex flex-column mb-4"
              style={{ justifyContent: "space-between" }}
            >
              <span className="counterText">₹ {count.toFixed(2)}*</span>
              <span className="costText">
                Cost for Rs. 1,000 for
                <br />
                10 days!!
              </span>
              <span className="unbeatableText mt-2">
                Unbeatable costs compared to the Rs. 30 to 55 charged by others.
              </span>
            </div>
            <span
              style={{
                position: "absolute",
                bottom: 10,
                right: "15%",
                fontSize: 14,
                fontStyle: "italic",
                color: "lightblue",
              }}
            >
              * Subject to lender's approval
            </span>
          </div>
        </div>

        <div
          className="bgImagem text-center mt-5"
          style={{ position: "relative" }}
        >
          <img
            src={pigim}
            className="img-fluid imgRupees"
            style={{ width: "100%" }}
            alt="Cinque Terre"
          />
          <div
            className="overlay-text"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="col-md-12 d-flex flex-column text-center ">
              <span className="counterText">₹ {count.toFixed(2)}*</span>
              <span className="costText">
                Cost for Rs. 1,000 for
                <br />
                10 days!!
              </span>
              <span className="unbeatableText mt-2">
                Unbeatable costs compared to the Rs.<br></br>30 to 55 charged by
                others.
              </span>
              <span
                style={{
                  textAlign: "center",
                  position: "absolute",
                  bottom: 10,
                  left: 0,
                  right: 0,
                  fontSize: 12,
                  fontStyle: "italic",
                  color: "lightblue",
                }}
              >
                * Subject to lenders's approval
              </span>
              {/* <div className="mt-4" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <img src={googlePlay} style={{ width: "150px" }} className="img-fluid imgRupees" alt="Cinque Terre" />
          </div> */}
            </div>
          </div>
        </div>

        <div className="container pt-5 pb-5 desktopFooter">
          <div className="d-flex justify-content-between row">
            <div className="col-md-6 ">
              <img
                src={mobile}
                className="img-fluid"
                style={{ height: "500px" }}
                alt="Cinque Terre"
              />
            </div>
            <div
              className="col-md-6"
              style={{
                flexDirection: "column",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                {/* <div className="mt-5 simplyFyLaout ">
                <span className="simplyfy">Simplify. Empower. Thrive. Build.</span>
              </div> */}
                <div className="mt-2 simplyFyLaout">
                  <span className="unlockFunds">
                    Unlock funds in <br></br>4 easy steps.
                  </span>
                  <div className="byeText mt-5">
                    <span className="goodbye">
                      Say goodbye to waiting and hello to financial<br></br>
                      flexibility.
                    </span>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div className="mt-3">
                        <Button
                          onClick={handleDownloadButton}
                          style={{
                            background: "none",
                            border: "none",
                            padding: 0,
                          }}
                          className="downloadNow "
                        >
                          <img
                            src={playstore}
                            style={{ width: 200 }}
                            alt="Cinque Terre"
                          />
                        </Button>
                      </div>
                      <div
                        className="mt-3"
                        style={{
                          marginLeft: "45px",
                          flexDirection: "column",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span style={{ fontSize: 18 }}>
                          <b>Get In Touch</b>
                        </span>
                        <button
                          className="btn p-0"
                          style={{ outline: "none" }}
                          onClick={handleContactUsClick}
                        >
                          signup@instape.com
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mobileFooter pb-5">
          <div className="d-flex justify-content-between row">
            <div className="col-md-6 mt-5 ">
              {/* <div className="mt-5 simplyFyLaout ">
              <span className="simplyfy">Simplify. Empower. Thrive. Build.</span>
            </div> */}
              <div className="mt-2 simplyFyLaout">
                <span className="unlockFunds">
                  Unlock funds in <br></br>4 easy steps.
                </span>
              </div>
            </div>
            <div
              className="col-md-6"
              style={{
                flexDirection: "column",
                display: "flex",
                justifyContent: "space-between",
                marginTop: 30,
              }}
            >
              <div>
                <img
                  src={mobile}
                  className="img-fluid mobileImgFooter"
                  alt="Cinque Terre"
                />
              </div>
              <div className="byeText mt-5">
                <span className="goodbye">
                  Say goodbye to waiting and hello to financial flexibility.
                </span>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 10,
                  }}
                >
                  <div className="mt-3">
                    <Button
                      onClick={handleDownloadButton}
                      style={{ background: "none", border: "none", padding: 0 }}
                      className="downloadNow "
                    >
                      <img
                        src={playstore}
                        style={{ width: 200 }}
                        alt="Cinque Terre"
                      />
                    </Button>
                  </div>
                  <div
                    className="mt-3"
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ fontSize: 18 }}>
                      <b>Get In Touch</b>
                    </span>
                    <span onClick={handleContactUsClick}>
                      signup@instape.com
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Home;
