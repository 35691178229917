import React from "react";
import "./css/BankPartner.css";
import logo from "../components/assets/ssBank.png";
import hindon from "../components/assets/hindon.png";
import { Link } from "react-router-dom";
import { BiLinkExternal } from "react-icons/bi";

const Support = () => {
  const handleContact = () => {
    window.open(
      "https://www.suryodaybank.com/loans/EWA-Personal-Loans-instape",
      "_blank"
    );
  };
  const handleContact2 = () => {
    window.open("https://hindon.co/lending-service-providers/", "_blank");
  };
  const handleTermsConditionsClick = () => {
    window.open("/terms_of_use", "_blank");
  };
  return (
    <>
      <div
        className="container mb-5"
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div>
          <h1 style={{ textAlign: "center" }}> Our Lending Partners</h1>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              marginTop: 80,
              flexDirection: "column",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "300px",
              textAlign: "center",
            }}
          >
            <img src={logo} className="bankLogo" alt="Cinque Terre" />
            <span style={{ marginTop: 10, fontWeight: 600, color: "#2d348e" }}>
              Suryoday Small Finance Bank Limited
            </span>
            <div
              role="button"
              style={{
                marginTop: 19,
                width: 250,
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={handleContact}
              className="cta-btn-outline"
            >
              <span className="contact">Visit Website</span>
            </div>
          </div>
          <div
            style={{
              marginTop: 80,
              flexDirection: "column",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "300px",
              textAlign: "center",
            }}
          >
            <img src={hindon} className="bankLogo2" alt="Cinque Terre" />
            <span style={{ marginTop: 10, fontWeight: 600, color: "#2d348e" }}>
              Hindon Mercantile Limited
            </span>
            <div
              role="button"
              style={{
                marginTop: 19,
                width: 250,
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={handleContact2}
              className="cta-btn-outline"
            >
              <span className="contact">Visit Website</span>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div
        className="container mb-5"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1 style={{ textAlign: "center" }}> Grievance</h1>
        <b style={{ textAlign: "center" }}>
          If you are not happy with the solution provided reach out to Grievance
          Officer.
        </b>
        <div className="write-to-us-section mt-3">
          <p>
            <b>Name</b>: Mr. Durairaj Ponnusamy
          </p>
          <p>
            <b>Address</b>: InstaPe Synergies Private Limited, Tainwala House,
            Road no.18, MIDC, Marol Andheri (East), MUMBAI 400093{" "}
          </p>

          <p>
            <b>Phone number</b>: +91 86554 08338
          </p>
          <p>
            <b>Timings</b>: From 9:30am to 6:00pm (2nd and 4th Saturday Holiday){" "}
          </p>
          <p>
            <b> Email ID</b>:
            <a
              href="mailto:nodalofficer@instape.com"
              style={{ color: "blue", marginLeft: 2 }}
            >
              nodalofficer@instape.com
            </a>
          </p>
        </div>
      </div>
      <div className="container d-flex justify-content-center mb-5">
        <div className="me-4">
          <Link
            target={"_blank"}
            to="/terms_of_use"
            style={{ color: "#216FB8" }}
          >
            <h5>
              <BiLinkExternal color="#216FB8" /> Terms of Use
            </h5>
          </Link>
        </div>
        <div style={{ cursor: "pointer" }}>
          <Link
            target={"_blank"}
            to="/privacy-policy"
            style={{ color: "#216FB8" }}
          >
            <h5>
              <BiLinkExternal color="#216FB8" /> Privacy Policy
            </h5>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Support;
