import React from "react";
import logo from "../components/assets/logoWhite.svg";
import "../components/Footer.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
const Footer = () => {
  const navigate = useNavigate();
  const handleTermsConditionsClick = () => {
    window.open("/terms_of_use", "_blank");
  };
  const handleRbiSachet = () => {
    window.open("https://sachet.rbi.org.in", "_blank");
  };
  const handleGrivance = () => {
    window.open("/grievance", "_blank");
  };
  const handlePolicy = () => {
    window.open("/privacy-policy", "_blank");
  };
  const handleBankPartners = () => {
    window.open("/lending-partners", "_blank");
  };

  const handleContactUsClick = () => {
    // window.location.href = "mailto:signup@instape.com";
    navigate("/connect");
  };
  return (
    <div className="container pt-4 ">
      <div>
        <div className="d-flex justify-content-center justify-content-md-between align-items-center ">
          <img
            src={logo}
            className="img-fluid"
            alt="Cinque Terre"
            height="30"
          />
          <div className="mobileFooterready">
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ marginRight: 10 }} className="readytoGet">
                Ready to get started?
              </span>
              <Button
                onClick={handleContactUsClick}
                style={{ backgroundColor: "#FFF", color: "#216FB8" }}
              >
                Contact Us
              </Button>
            </div>
          </div>
        </div>
        <div className="desktopFooterReady mt-3">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span style={{ marginRight: 10 }} className="readytoGet">
              Ready to get started?
            </span>
            <Button
              onClick={handleContactUsClick}
              style={{ backgroundColor: "#FFF", color: "#216FB8" }}
            >
              Contact Us
            </Button>
          </div>
        </div>
      </div>

      <hr className="mt-4" style={{ color: "#fff" }}></hr>
      <span
        className="mobileAddress"
        style={{ color: "white", textAlign: "center" }}
      >
        <b>InstaPe Synergies Private Limited</b>
        <br></br>Tainwala House, Road no. 18, MIDC,<br></br> Marol Andheri East,
        Mumbai 400093
      </span>
      <div className="d-flex mt-4 justify-content-center justify-content-md-end">
        <div
          className="col-md-12 d-flex footNav"
          style={{
            color: "white",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <ul className="list-unstyled mobile-footer desktopAddress">
            <span>
              <b>InstaPe Synergies Private Limited</b>
              <br></br>
              Tainwala House,Road no. 18, MIDC, <br></br> Marol Andheri East,
              Mumbai 400093
            </span>
          </ul>
          <ul className="list-unstyled mobile-footer d-flex">
            <li style={{ marginRight: "10px", cursor: "pointer" }}>
              <button
                className="btn text-white p-0"
                type="button"
                onClick={handleBankPartners}
              >
                <span>Lending Partners</span>
              </button>
            </li>
            <li style={{ marginRight: "10px", cursor: "pointer" }}>
              <button
                className="btn text-white p-0"
                type="button"
                onClick={handleRbiSachet}
              >
                <span>RBI Sachet Portal</span>
              </button>
            </li>
            <li style={{ marginRight: "10px", cursor: "pointer" }}>
              <button
                className="btn text-white p-0"
                type="button"
                onClick={handleGrivance}
              >
                <span>Grievance Redressal</span>
              </button>
            </li>
            <li style={{ marginRight: "10px", cursor: "pointer" }}>
              <button
                className="btn text-white p-0"
                type="button"
                onClick={handleContactUsClick}
              >
                <span>Contact Us</span>
              </button>
            </li>
            <li style={{ marginRight: "10px", cursor: "pointer" }}>
              <button
                className="btn text-white p-0"
                type="button"
                onClick={handleTermsConditionsClick}
              >
                <span>Terms of Use</span>
              </button>
            </li>
            <li style={{ marginRight: "10px", cursor: "pointer" }}>
              <button
                className="btn text-white p-0"
                type="button"
                onClick={handlePolicy}
              >
                <span>Privacy Policy</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div
        className=" pb-3 col-sm-12 col-md-12 d-flex justify-content-center justify-content-md-end"
        style={{ color: "white", fontSize: 12 }}
      >
        <span>© 2023 InstaPe. All rights reserved.</span>
      </div>
    </div>
  );
};

export default Footer;
